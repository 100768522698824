<template>
<div class="absolute w-full h-full top-0 left-0 flex flex-col items-center justify-between bg-gradient-to-tr from-teal-500 to-teal-300 dark:from-teal-700 dark:to-teal-500 z-50">
    <div class="relative bg-white dark:bg-gray-900 w-11/12 md:max-w-md mx-auto rounded-lg shadow-lg md:mt-10">
      <div class="absolute m-1 top-0 right-0 cursor-pointer" @click="$router.push('/')">
          <svg class="h-8 w-8 p-2 fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>
        </div>
      <div class="flex w-full items-center text-gray-800 dark:text-white">
        <svg class="w-full h-12 mt-3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <circle fill="#38b2ac" cx="12" cy="12" r="12"/>
            <path fill="#FFF" fill-rule="nonzero" d="M17.57895 17.61404H13.6842l1.61404-7.57895-4.1579 7.57895H8.7544L7.9649 9.77193l-1.6842 7.8421H4.70174L7.07018 6.47369h4.47368l.54386 6.2807 3.36842-6.2807h4.50877z"/>
          </g>
        </svg>
      </div>
      <div class="py-4 px-6">
        <h3 class="font-semibold dark:text-white mb-6">{{ $t('login') }}</h3>
        <div v-if="errorMessage" class="p-2 text-sm rounded mb-4 bg-red-200 text-red-700 border border-red-700">
          {{errorMessage}}
        </div>
        <form @submit.prevent="login()" class="dark:text-white">
          <span class="text-sm font-bold">{{ $t('email') }}</span>
          <input v-model="email" class="mt-1 mb-2" type="email" name="email" :placeholder="$t('email')">
          <span class="text-sm font-bold">{{ $t('password') }}</span>
          <input v-model="password" class="mt-1 mb-2" type="password" name="password" :placeholder="$t('password')">
          <button class="primary mt-6 mb-3 w-full flex items-center justify-center" type="submit" :disabled="isLoading">
          <template v-if="isLoading">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </template>
          <span v-else>{{ $t('login') }}</span>
          </button>
        </form>
      </div>
    </div>
    <Footer class="text-center"></Footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ApiClient from '@/assets/js/ApiClient.js'
import Footer from '@/components/Footer'

export default {
  components: {
    Footer
  },
  data () {
    return {
      email: '',
      password: '',
      errorMessage: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['hasValidSubscription']),
    isLoading () {
      return this.loading
    }
  },
  methods: {
    ...mapActions(['updateAccessToken', 'updateRefreshToken', 'initialize']),
    login () {
      this.loading = true
      this.errorMessage = null
      const body = {
        email: this.email,
        password: this.password
      }
      ApiClient.post('api/login', body).then(response => {
        const data = response.data
        this.updateAccessToken(data.access_token)
        this.updateRefreshToken(data.refresh_token)
        this.initialize().then(() => {
          if (this.hasValidSubscription) {
            this.$router.push('/')
          } else {
            this.$router.push('/account')
          }
          this.loading = false
        })
      }).catch(e => {
        this.errorMessage = this.$t('login_failed')
        this.loading = false
      })
    }
  }
}
</script>
